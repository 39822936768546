import * as React from "react";
import {
  Grid,
  Input,
  Dropdown,
  Menu,
  Icon,
  Pagination,
  PaginationProps,
  Loader,
  DropdownItemProps,
} from "semantic-ui-react";
import { get, debounce } from "lodash";

import { AppContext } from "../../providers";
import { SearchFilter, OrderType, OrderByType, Page, FilterType } from "../../interfaces";

import "./styles.scss";

const sortStatus = [
  { key: 1, text: "Title", value: "title" },
  { key: 2, text: "Created Date", value: "createdDate" },
];

interface Props {
  /**
   * Component
   */
  content: JSX.Element;
  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any[]) => void;

  /**
   * Handle to refresh content with the result
   */
  refreshAllDataToExport?: (result?: any[]) => void;

  /**
   * Search function name
   */
  searchFunction: (
    coachId: string,
    sort: string,
    filters?: SearchFilter | null,
    page?: number | string,
    size?: number
  ) => Promise<Page<any>>;

  showSortBy?: boolean;
  showSearchTerm?: boolean;
  filters?: FilterType[];
  secondFilters?: FilterType[];

  defaultFilters?: string[];

  customSort?: DropdownItemProps[] | undefined;
  defaultCustomSort?: string;

  multiple?: boolean;
  customCoachId?: string; // replace CustomID because is used with playerId too
  pageable?: boolean;
  forceRefresh?: boolean;
  hideWhenNotSearch?: boolean;
  showSearch?: boolean;
  customSize?: number;
  filterPlaceholder?: string;

  secondFilterPlaceholder?: string;

  /**
   * Option. It is used to search by campaign id
   */
  campaignId?: string;

  /**
   * Handle to refresh content with the result
   */
  onSearchedHandler?: (searchActive: boolean) => void;
}

const controller = new AbortController();

const SearchFC: React.FC<Props> = ({
  showSearchTerm = true,
  content,
  customCoachId,
  showSortBy = true,
  filters = [
    { key: 1, text: "Published", value: "ACTIVE" },
    { key: 2, text: "Unpublished", value: "DRAFT" },
  ],
  multiple = false,
  defaultFilters,
  pageable = true,
  forceRefresh = false,
  customSort = sortStatus,
  hideWhenNotSearch = false,
  showSearch = true,
  customSize,
  defaultCustomSort = "createdDate",
  filterPlaceholder = "Filter by",
  secondFilterPlaceholder = "Filter by",
  secondFilters,
  campaignId,
  refreshData,
  searchFunction,
  onSearchedHandler,
  refreshAllDataToExport,
}) => {
  const { userContext } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [totalPages, setTotalPages] = React.useState<number>(0);

  const [order, setOrder] = React.useState<OrderType>("DESC");
  const [searchTitle, setSearchTitle] = React.useState<string | null>(null);
  const [searchStatus, setSearchStatus] = React.useState<string | undefined>(undefined);
  const [secondSearchStatus, setSecondSearchStatus] = React.useState<string[] | undefined>(undefined);
  const [orderStatus, setOrderStatus] = React.useState<OrderByType>("createdDate");
  const [results, setResults] = React.useState<any[] | undefined>(undefined);

  const ascOrder = order === "ASC";
  const firstFilterType = filters;
  const secondFilterType = secondFilters;

  React.useEffect(() => {
    search();
  }, [searchStatus, searchTitle, order, orderStatus, searchFunction, secondSearchStatus]);

  React.useEffect(() => {
    search();
  }, []);

  React.useEffect(() => {
    if (refreshAllDataToExport) {
      refreshAllDataToExport(results);
    }
  }, [results]);

  React.useEffect(() => {
    if (forceRefresh) {
      search();
    }
  }, [forceRefresh]);

  const search = (
    activePage?: number | string,
    orderType: OrderType = order,
    searchContent: string | null = searchTitle,
    status: string | undefined = searchStatus
  ) => {
    setLoading(true);
    setResults(() => undefined);

    const filterBody: SearchFilter = {
      campaignId,
      title: searchContent,
      status: status !== undefined ? [status] : defaultFilters ? defaultFilters : [],
      promptTypes:
        secondSearchStatus !== undefined ? secondSearchStatus : secondFilters ? ["REELS", "FEED"] : undefined,
      abortController: controller,
    };

    const id: string = customCoachId ? customCoachId : get(userContext, "coach.id", "");

    searchFunction(id, `${orderStatus},${orderType}`, filterBody, activePage, customSize)
      .then(response => {
        setTotalPages(response.totalPages ?? 1);
        refreshData(response.content);
        if (refreshAllDataToExport && (searchContent || status || !hideWhenNotSearch)) {
          loadAllData(status, searchContent);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const nextPage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps): void => {
    search(Number(data.activePage) - 1);
  };

  const searchByContent = (query: string): void => {
    setSearchTitle(query);
  };

  const changeOrder = (): void => {
    const newOrder = ascOrder ? "DESC" : "ASC";
    setOrder(newOrder);
  };

  const delayedQuery = React.useRef(debounce((q: string) => searchByContent(q), 500)).current;

  const onChange = (e: any) => {
    delayedQuery(e.target.value);
  };

  const searchActive = (searchTitle !== "" && searchTitle !== null) || searchStatus !== undefined;

  React.useEffect(() => {
    if (onSearchedHandler) {
      onSearchedHandler(searchActive);
    }
  }, [searchActive]);

  const loadAllData = async (status: string | undefined = searchStatus, searchContent: string | null = searchTitle) => {
    let page = 0;
    let total = 1;
    let calculatingResult: any[] = [];
    const filterBody: SearchFilter = {
      campaignId,
      title: searchContent,
      status: status !== undefined ? [status] : defaultFilters ? defaultFilters : [],
    };

    const coachId = customCoachId ? customCoachId : get(userContext, "coach.id", "");

    while (page < total) {
      const response = await searchFunction(coachId, `${orderStatus},${order}`, filterBody, page, 50);

      if (page === 0) {
        total = response.totalPages!!;
        calculatingResult = response.content;
      } else {
        calculatingResult = calculatingResult.concat(response.content);
      }
      page++;
    }
    setResults(() => calculatingResult);
  };

  return (
    <div>
      {showSearch && (
        <Menu secondary className={"filterMenu"}>
          {/* SEARCH BY ... */}

          {showSearchTerm && (
            <Menu.Item className={"searchMenu"}>
              <Input className={"searchInput"} icon="search" placeholder="Search..." onChange={onChange} />
            </Menu.Item>
          )}

          {/* FILTER BY - First option */}
          {firstFilterType && firstFilterType?.length > 0 && (
            <Menu.Item>
              <Dropdown
                search
                selection
                placeholder={filterPlaceholder}
                className="searchInput"
                multiple={multiple}
                selectOnBlur={false}
                onChange={(e, data) => {
                  setSearchStatus(data.value !== "" ? (data.value as string) : undefined);
                }}
                clearable
                options={firstFilterType}
              />
            </Menu.Item>
          )}
          {/* FILTER BY TYPE - Second Filter - Optional */}
          {secondFilterType && secondFilterType?.length > 0 && (
            <Menu.Item>
              <Dropdown
                search
                selection
                placeholder={secondFilterPlaceholder}
                className="searchInput"
                multiple={true}
                selectOnBlur={false}
                onChange={(e, data) => {
                  setSecondSearchStatus(data.value !== undefined ? (data.value as string[]) : []);
                }}
                clearable
                options={secondFilterType}
              />
            </Menu.Item>
          )}
          {/* SORT BY  */}
          {showSortBy && (
            <Menu.Menu floated="right">
              <Menu.Item>
                <Dropdown
                  search
                  selection
                  placeholder={"Sort by"}
                  className="searchInput"
                  onChange={(e, data) => {
                    setOrderStatus(data.value !== "" ? (data.value as OrderByType) : "createdDate");
                  }}
                  defaultValue={defaultCustomSort}
                  options={customSort}
                />
              </Menu.Item>

              {/* ORDER BY */}
              <Menu.Item className={"menuIcon"} icon onClick={changeOrder}>
                <Icon className={"negativeLeftMargin"} name={ascOrder ? "arrow up" : "arrow down"} />
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>
      )}
      {loading ? <Loader active size="large" /> : (searchActive || !hideWhenNotSearch) && content}

      {pageable && totalPages > 0 && (
        <Grid centered>
          <Pagination
            defaultActivePage={1}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            totalPages={totalPages}
            onPageChange={nextPage}
          />
        </Grid>
      )}
    </div>
  );
};

export const SearchComponent = SearchFC;
