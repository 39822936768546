import * as React from "react";

import { ProspectResponse } from "../../../../interfaces";
import { PlayersServices } from "../../../../services";
import UserTable from "../../UserTable";
import { Table } from "semantic-ui-react";
import { printDate } from "../../../../utils";

const AdminProspectListFC: React.FC = () => {
  return (
    <UserTable
      headers={[
        { title: "Email", width: 1 },
        { title: "Created Date", width: 1 },
        { title: "Referral Code", width: 1 },
      ]}
      queryFunction={query => {
        return PlayersServices.searchAdminProspects(query.searchTerm, query.page, query.size);
      }}
      renderRow={(prospect: ProspectResponse) => (
        <>
          <Table.Cell className={"flexCell"}>{prospect.email}</Table.Cell>
          <Table.Cell>{printDate(prospect.createdDate!)}</Table.Cell>
          <Table.Cell>{prospect.sharedLinkId}</Table.Cell>
        </>
      )}
    />
  );
};

export const AdminProspectList = AdminProspectListFC;
