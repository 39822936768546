import * as React from "react";

import { ThumbnailPreview, StickerCardStyle } from "./styled";
import "./styles.scss";
import { ActionList } from "../../ActionList";
import { Icon } from "semantic-ui-react";

interface OwnProps {
  uri: string;
  isAutosticker: boolean;
  onDelete: () => void;
  onSetAutosticker: () => void;
}

const StickerCardFC: React.FC<OwnProps> = ({ uri, isAutosticker, onSetAutosticker, onDelete }: OwnProps) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (uri) {
      setThumbnail(uri);
    }
  }, [uri]);

  const ACTION_AUTOSTICKER = "Set as auto-sticker";
  const ACTION_DELETE = "Delete";
  const actions = [
    { content: ACTION_AUTOSTICKER, icon: "circular star inverted yellow small" },
    { content: ACTION_DELETE, icon: "trash" },
  ];

  const handleAction = (index: number) => {
    switch (actions[index]?.content) {
      case ACTION_AUTOSTICKER:
        onSetAutosticker();
        break;
      case ACTION_DELETE:
        onDelete();
        break;
    }
  };

  return (
    <StickerCardStyle className={"StickerCard"}>
      <ThumbnailPreview thumbnailurl={thumbnail} />
      {isAutosticker && (
        <div className={"autostickerIcon"}>
          <Icon name={"star"} color={"yellow"} size={"small"} circular inverted />
        </div>
      )}
      <div className={"stickerMenu"}>
        <ActionList items={actions} handleAction={async index => handleAction(index)} iconName={"ellipsis vertical"} />
      </div>
    </StickerCardStyle>
  );
};

export const SmallStickerCard = StickerCardFC;
