import { WebSettings } from "../../../../../interfaces";
import * as MODEL from "../../../../../interfaces/models";

export const appSettings: MODEL.PermissionField[] = [
  {
    id: "externalPost",
    name: WebSettings.EXTERNAL_POST,
    label: "Feed for Posts made outside of SocialCoach",
  },
  {
    id: "failedPost",
    name: WebSettings.FAILED_POST_NOTIFICATION,
    label: "Email notifications for failed posts",
  },
  {
    id: "tiktok",
    name: WebSettings.TIKTOK,
    label: "TikTok Functionality",
  },
  {
    id: "coachSummaryReport",
    name: WebSettings.SUMMARY_REPORT_COACH,
    label: "Monthly Account Summary Reports",
  },
  {
    id: "costCenter",
    name: WebSettings.COST_CENTER,
    label: "Include Cost Center data in organization’s experience",
  },

  {
    id: "scPlusTier",
    name: WebSettings.SC_PLUS_TIER,
    label: "Allow Coaches to upgrade players to SocialCoach+",
  },
  {
    id: "accessScriptLibrary",
    name: WebSettings.ACCESS_SCRIPT_LIBRARY,
    label: "Allow all players to access Script Library",
  },
  {
    id: "manageScriptLibrary",
    name: WebSettings.MANAGE_SCRIPT_LIBRARY,
    label: "Allow coach to manage Script Library",
  },
  {
    id: "accessMVP",
    name: WebSettings.ACCOUNT_MVP_ACCESS,
    label: "Allow access to MVP",
  },
  {
    id: "accessCalendar",
    name: WebSettings.CALENDAR_ACCESS,
    label: "Allow access to Calendar",
  },
];
