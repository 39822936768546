import * as React from "react";

import { Admin } from "../../../../interfaces";
import { UsersService } from "../../../../services";
import { Table } from "semantic-ui-react";
import UserTable from "../../UserTable";

const AdminsListFC: React.FC = () => {
  return (
    <UserTable
      hideSearch
      queryFunction={query => UsersService.getAdmins(query.page, query.size)}
      headers={[
        { title: "Name", width: 1 },
        { title: "Email", width: 1 },
      ]}
      renderRow={(admin: Admin) => (
        <>
          <Table.Cell>
            {admin.firstName} {admin.lastName}
          </Table.Cell>
          <Table.Cell>{admin.email}</Table.Cell>
        </>
      )}
    />
  );
};

export const AdminsList = AdminsListFC;
