import { Button, Modal, ModalDescription } from "semantic-ui-react";
import * as React from "react";
import { PlayerSearchResponse } from "../../../../../interfaces";
import { PlayersServices } from "../../../../../services";
import { useState } from "react";

interface OwnProps {
  player?: PlayerSearchResponse;
  open: boolean;
  onClose: () => void;
}

export const DeletePlayerModal: React.FC<OwnProps> = ({ player, open, onClose }) => {
  const [loading, setLoading] = useState(false);

  const deletePlayer = () => {
    setLoading(true);
    PlayersServices.delete(player?.playerId!!)
      .then(() => {
        setLoading(false);
        alert("The player has been deleted");
        onClose();
      })
      .catch(e => alert(e));
  };

  return (
    <Modal open={open} size={"small"} onClose={onClose} closeIcon={true}>
      <Modal.Header>Warning!!</Modal.Header>
      <Modal.Content>
        <ModalDescription>
          <p className="pDelete">
            <strong>WARNING!!!</strong>
            {` Deleting this player will permanently DELETE all data associated
          to this account ${player?.email} including profile data, posts, and subscription history.
          This cannot be undone.`}
          </p>
        </ModalDescription>
      </Modal.Content>

      <Modal.Actions>
        <Button secondary data-elm-id={`basicModalNoBtn`} onClick={onClose}>
          Cancel
        </Button>
        <Button primary data-elm-id={`basicModalYesBtn`} onClick={deletePlayer} loading={loading}>
          DELETE
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
