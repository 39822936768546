import React, { useMemo } from "react";

import { Button, Label, Modal, ModalDescription, Table } from "semantic-ui-react";

import * as DTO from "../../../../../interfaces";
import { getStatusText, printBasicShortDate } from "../../../../../utils";

import { CoachPermissions } from "../CoachPermissions";
import { ReportServices } from "../../../../../services/ReportServices";
import "./styles.scss";
import { AppContext } from "../../../../../providers";
import { CoachService } from "../../../../../services";
import { modalReducer } from "./modalReducer";
import { TitleSubtitleCell, ActionList } from "../../../../../components";
import { CoachInformation } from "../CoachInformation";
interface OwnProps {
  coachAccount: DTO.CompleteAccount;
  coachStats?: DTO.CoachStatistic;
  coachIndex: number;

  handlerRefresh: () => void;
  handlerImpersonate: (username: string) => void;
}

const CoachCellFC: React.FC<OwnProps> = ({
  coachAccount,
  coachStats,
  coachIndex,
  handlerRefresh,
  handlerImpersonate,
}) => {
  const { profile, user, coach, signUpStep } = coachAccount;
  const [coachDetailsModalOpen, setCoachDetailsModalOpen] = React.useState<boolean>(false);
  const [coachPermissionsModalOpen, setCoachPermissionsModalOpen] = React.useState<boolean>(false);
  const [sendingReport, setSendingReport] = React.useState<boolean>(false);
  const { userContext } = React.useContext(AppContext);

  const [state, dispatch] = React.useReducer(modalReducer, { open_edit: false, open_delete: false });

  const tags: string[] = useMemo(() => {
    const list = [];
    const groupStrings = user.groups.map(g => g.toString());
    if (groupStrings.includes("ADMIN")) {
      list.push("Admin");
    }
    if (groupStrings.includes("EDITOR")) {
      list.push("Editor");
    }
    if (user.isDemo) {
      list.push("Demo");
    }
    return list;
  }, [user.groups, user.isDemo]);

  const coachModal = () => {
    return (
      <Modal
        data-elm-id={`admin-dashboard-modal-${user.userId}`}
        open={coachDetailsModalOpen}
        onClose={() => setCoachDetailsModalOpen(false)}
        closeIcon={true}
      >
        <Modal.Header> Coach Information</Modal.Header>
        <Modal.Content>
          <CoachInformation
            profile={profile!!}
            user={user}
            coach={coach!!}
            signUpStep={signUpStep!!}
            handleUpdateSuccess={() => handlerRefresh()}
          />
        </Modal.Content>
      </Modal>
    );
  };

  const deleteCoach = () => {
    CoachService.delete(coach?.id!!)
      .then(() => {
        alert("The coach has been deleted.");
        handlerRefresh();
      })
      .catch(e => alert(e));
  };

  const deleteModal = () => {
    return (
      <Modal
        open={state.open_delete}
        size={"small"}
        onClose={() => dispatch({ type: "close_delete" })}
        closeIcon={true}
      >
        <Modal.Header>Warning!!</Modal.Header>
        <Modal.Content>
          <ModalDescription>
            <p className="pDelete">
              <strong>WARNING!!!</strong>
              {` Deleting this coach will permanently DELETE all data associated to this user ${user.email} and all their players
                 including profile data, media library, campaigns, posts, and subscription history.

              This cannot be undone.`}
            </p>
          </ModalDescription>
        </Modal.Content>

        <Modal.Actions>
          <Button
            secondary
            data-elm-id={`basicModalNoBtn`}
            onClick={() => {
              dispatch({ type: "close_delete" });
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            data-elm-id={`basicModalYesBtn`}
            onClick={() => {
              dispatch({ type: "close_delete" });
              deleteCoach();
            }}
          >
            DELETE
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const handleAction = (index: number): void => {
    switch (index) {
      case 0:
        return setCoachDetailsModalOpen(true);
      case 1:
        return setCoachPermissionsModalOpen(true);
      case 2:
        return handlerImpersonate(user.email!);
      case 3:
        return sendReportHandler();
      case 4:
        return dispatch({ type: "open_delete" });
    }
  };

  const report = useMemo(() => coachStats?.featureEngagementReport, [coachStats?.featureEngagementReport]);

  const percent = (v1?: number, v2?: number): string | undefined => {
    if (v1 !== undefined && v2 !== undefined) {
      return `${(v1 / v2).toPrecision(1)}%`;
    }
    return undefined;
  };

  const diffLabel = (now?: number, prev?: number): string => {
    if (now !== undefined && prev != undefined) {
      const diff = now - prev;
      return `${now} (${diff >= 0 ? "+" : "-"}${diff})`;
    } else if (now !== undefined) {
      return `${now}`;
    } else return "";
  };

  const sendReportHandler = () => {
    setSendingReport(true);
    ReportServices.sendCoachReport(coachAccount.coach!!.id)
      .then(() => {
        alert("Success! Your report was sent to your pre-selected email address.");
      })
      .catch(() => {
        alert("Oops. Something went wrong. Please refresh this page and try again.");
      })
      .finally(() => {
        setSendingReport(false);
      });
  };

  const actions = useMemo(() => {
    const list = [
      {
        content: "Edit",
        icon: "pencil",
      },
      {
        content: "Coach Settings",
        icon: "cogs",
      },
      {
        content: "Impersonate",
        icon: "sign-in",
      },
      {
        content: "Send Report",
        icon: "paper plane",
      },
    ];
    if (
      coach?.coachStatus === DTO.CoachStatuses.INACTIVE &&
      userContext?.account.scGroups?.includes(DTO.UserGroups.SYSTEM_ADMIN)
    ) {
      list.push({
        content: "Delete Coach",
        icon: "trash",
      });
    }

    return list;
  }, [coach?.coachStatus, userContext?.account.scGroups]);

  return (
    <Table.Row key={coachIndex}>
      <Table.Cell>
        <div>
          <code>{coach?.id}</code> - {profile?.firstName} {profile?.lastName}
        </div>
        <div>{user.email}</div>
        <div>{coach?.companyName ? coach.companyName : <i>No Company Name</i>}</div>
        {tags.map(tag => {
          return (
            <Label key={`coach-tag-${tag}-${coach?.id}`} size={"tiny"}>
              {tag}
            </Label>
          );
        })}
      </Table.Cell>
      <Table.Cell>
        <div>{getStatusText(coach?.coachStatus!)}</div>
        <div>Created: {printBasicShortDate(user?.createdDate)}</div>
        <div>Last Login: {printBasicShortDate(user?.latestLoginDate)}</div>
      </Table.Cell>

      {/* players */}
      <Table.Cell>{report?.totalPlayers}</Table.Cell>
      <Table.Cell>{report?.activePlayers}</Table.Cell>
      <Table.Cell>{report?.enrolledPlayers}</Table.Cell>
      <Table.Cell>{report?.scPlusPlayers}</Table.Cell>

      {/* prompts */}
      <Table.Cell>{report?.totalPrompts}</Table.Cell>
      <Table.Cell>{diffLabel(report?.prompts90, report?.promptsPrev90)}</Table.Cell>
      <Table.Cell>{report?.remainingPrompts}</Table.Cell>

      {/* feature use */}
      <Table.Cell>{diffLabel(report?.posts90, report?.postsPrev90)}</Table.Cell>
      <Table.Cell>{diffLabel(report?.projects90, report?.projectsPrev90)}</Table.Cell>
      <Table.Cell>{diffLabel(report?.pages90, report?.pagesPrev90)}</Table.Cell>

      <Table.Cell>
        <ActionList items={actions} handleAction={async index => handleAction(index)} />
      </Table.Cell>
      {coachModal()}
      {coachPermissionsModalOpen && (
        <CoachPermissions
          opened={coachPermissionsModalOpen}
          coach={coach!!}
          handleUpdateSuccess={() => {
            setCoachPermissionsModalOpen(false);
          }}
        />
      )}
      {deleteModal()}
    </Table.Row>
  );
};

export const CoachCell = CoachCellFC;
