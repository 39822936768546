import * as React from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { Tab, TabPane } from "semantic-ui-react";

import { AdminSideBar } from "../../../components";

import { WhiteContainer } from "../../../styling/baseStyle";

import "./styles.scss";
import { PlayersList } from "./Players";
import { CoachList } from "./Coaches";
import { AdminProspectList } from "./Prospects";
import { withRouter } from "react-router";
import { AdminsList } from "./Admins";
import { EditorsList } from "./Editors";
import { AssistantsList } from "./Assitants";
import { LibraryTags } from "../../../interfaces";
import { useEffect } from "react";
import { URLS } from "../../../utils";

type Props = RouteComponentProps;

export type UsersTab = "players" | "coaches" | "sub-coaches" | "admins" | "editors" | "prospects";

const UsersPageFC: React.FC<Props> = ({ history }) => {
  const tabs = [
    {
      menuItem: "Coaches",
      pane: (
        <Tab.Pane>
          <CoachList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Players",
      pane: (
        <Tab.Pane>
          <PlayersList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assistants",
      pane: (
        <Tab.Pane>
          <AssistantsList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Prospects",
      pane: (
        <Tab.Pane>
          <AdminProspectList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Admins",
      pane: (
        <Tab.Pane>
          <AdminsList />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Editors",
      pane: (
        <Tab.Pane>
          <EditorsList />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <WhiteContainer>
      <AdminSideBar history={history} />
      <div className={"hugePadding adminUserTabs"}>
        <Tab menu={{ secondary: true, pointing: true }} renderActiveOnly={false} panes={tabs} />
      </div>
    </WhiteContainer>
  );
};

export const UsersPage = withRouter(UsersPageFC);
