import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import { camelCase } from "lodash";

import * as DTO from "../interfaces";

const SUBSCRIPTIONS_SERVICE = `${Constants.API_URL}/coaches/subscriptions`;
const SUBSCRIPTIONS_NO_STATS_SERVICE = `${Constants.API_URL}/coaches/player-subscriptions`;
const SUBSCRIPTIONS_STATS_SERVICE_V2 = `${Constants.API_URL}/coaches/v3/player-stats-by-social-network`;
const PROSPECTS_SERVICE = `${Constants.API_URL}/prospects`;
const COACH_PLAYERS = `${Constants.API_URL}/coaches/players`;
const PLAYERS = `${Constants.API_URL}/players`;
const ACCOUNT_PLAYERS = `${Constants.API_URL}/accounts/players`;

export const camelizeKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export class PlayersServices {
  @catchServiceException()
  static async searchSubscribedPlayers(
    coachId: string,
    sort: string, // UNUSED
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.SubscribedPlayerSearchResponse>> {
    const body: { [key: string]: any } = {
      searchTerm: filters?.title,
    };

    if (filters?.status && filters?.status.length > 0 && filters?.status[0].length > 0) {
      body["subscriptionStatus"] = filters?.status[0];
    }

    const { data } = await axios.post<DTO.SubscribedPlayerSearchResponse[]>(
      `${SUBSCRIPTIONS_SERVICE}/${coachId}`,
      body,
      {
        withCredentials: true,
      }
    );

    const searchResponse: DTO.Page<DTO.SubscribedPlayerSearchResponse> = {
      content: data,
    };
    return searchResponse;
  }

  @catchServiceException()
  static async searchSubscribedPlayersWithStatsDashboard(
    coachId: string,
    signal: any,
    page: number | string = 0,
    size: number = 15,
    from?: string | undefined,
    until?: string | undefined,
    socialNetworks?: DTO.SocialNetworksId[]
  ): Promise<DTO.Page<DTO.SubscribedPlayerWithSplittedStatsSearchResponse>> {
    const body = {
      subscriptionStatus: [DTO.SubscriptionStatus.FREE_TRIAL, DTO.SubscriptionStatus.ACTIVE],
      intervalType: from || until ? "MONTHLY" : "ALL_TIME",
      from,
      until,
      socialNetworks,
    };

    // Check if there are any previous pending requests
    // if (typeof signal !== typeof undefined) {
    //   controller.abort();
    // }

    // cancelToken = CancelToken.source();

    const response = await axios.post<DTO.Page<DTO.SubscribedPlayerWithSplittedStatsSearchResponse>>(
      `${SUBSCRIPTIONS_STATS_SERVICE_V2}/${coachId}?page=${page}&size=${size}`,
      body,
      {
        withCredentials: true,
        signal,
        // cancelToken: cancelToken.token,
      }
    );

    return response.data;
  }

  @catchServiceException()
  static async getPlayer(coachId: string, playerId: number): Promise<DTO.SubscribedPlayerSearchResponse | any> {
    const body = {
      playerIds: [playerId],
    };

    const response = await axios.post<DTO.SubscribedPlayerSearchResponse[]>(
      `${SUBSCRIPTIONS_SERVICE}/${coachId}`,
      body,
      {
        withCredentials: true,
      }
    );

    if (response.status === 200 && response.data.length > 0) {
      return response.data[0];
    } else {
      throw Error("Player not found");
    }
  }

  @catchServiceException()
  static async delete(playerId: number): Promise<any> {
    const response = await axios.delete<any>(`${PLAYERS}/${playerId}`, {
      withCredentials: true,
    });

    return response;
  }

  static async searchSubscribedPlayersByTag(
    coachId: string,
    filters?: DTO.SearchFilter | null
  ): Promise<DTO.Page<DTO.SubscribedPlayerSearchResponse>> {
    const body: { [key: string]: any } = {
      searchTerm: filters?.title,
    };

    if (filters?.branchIds) {
      body["branchIds"] = filters?.branchIds;
    }
    if (filters?.regionIds) {
      body["regionIds"] = filters?.regionIds;
    }
    if (filters?.stateIds) {
      body["stateIds"] = filters?.stateIds;
    }

    if (filters?.playerIds) {
      body["playerIds"] = filters?.playerIds;
    }

    if (filters?.status && filters?.status[0].length > 0) {
      body["tags"] = filters?.status[0];
    }

    body["subscriptionStatus"] = [
      DTO.SubscriptionStatus.ACTIVE,
      DTO.SubscriptionStatus.FREE_TRIAL,
      DTO.SubscriptionStatus.PENDING_PAYMENT,
    ];

    const { data } = await axios.post<DTO.SubscribedPlayerSearchResponse[]>(
      `${SUBSCRIPTIONS_SERVICE}/${coachId}`,
      body,
      {
        withCredentials: true,
      }
    );

    const searchResponse: DTO.Page<DTO.SubscribedPlayerSearchResponse> = {
      content: data,
    };
    return searchResponse;
  }

  static async searchSubscribedAllPlayersByTag(
    coachId: string,
    filters?: DTO.SearchFilter | null
  ): Promise<DTO.Page<DTO.SubscribedPlayerSearchResponse>> {
    const body: { [key: string]: any } = {
      searchTerm: filters?.title,
    };

    if (filters?.branchIds) {
      body["branchIds"] = filters?.branchIds;
    }
    if (filters?.regionIds) {
      body["regionIds"] = filters?.regionIds;
    }
    if (filters?.stateIds) {
      body["stateIds"] = filters?.stateIds;
    }

    if (filters?.playerIds) {
      body["playerIds"] = filters?.playerIds;
    }

    if (filters?.status && filters?.status[0].length > 0) {
      body["tags"] = filters?.status[0];
    }

    body["subscriptionStatus"] = [
      DTO.SubscriptionStatus.ACTIVE,
      DTO.SubscriptionStatus.FREE_TRIAL,
      DTO.SubscriptionStatus.PENDING_PAYMENT,
      DTO.SubscriptionStatus.CANCELED,
    ];

    const { data } = await axios.post<DTO.SubscribedPlayerSearchResponse[]>(
      `${SUBSCRIPTIONS_SERVICE}/${coachId}`,
      body,
      {
        withCredentials: true,
      }
    );

    const searchResponse: DTO.Page<DTO.SubscribedPlayerSearchResponse> = {
      content: data,
    };
    return searchResponse;
  }

  static async getSubscribedAllPlayers(coachId: string): Promise<DTO.Page<DTO.SubscribedPlayerSearchResponse>> {
    const body = {
      subscriptionStatus: [
        DTO.SubscriptionStatus.ACTIVE,
        DTO.SubscriptionStatus.FREE_TRIAL,
        DTO.SubscriptionStatus.PENDING_PAYMENT,
      ],
    };

    const { data } = await axios.post<DTO.SubscribedPlayerSearchResponse[]>(
      `${SUBSCRIPTIONS_SERVICE}/${coachId}`,
      body,
      {
        withCredentials: true,
      }
    );

    const searchResponse: DTO.Page<DTO.SubscribedPlayerSearchResponse> = {
      content: data,
    };
    return searchResponse;
  }

  static async searchSubscribedPlayersByStatus(
    coachId: string,
    sort: string, // UNUSED
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.SubscribedPlayerSearchResponse>> {
    const body: { [key: string]: any } = {
      searchTerm: filters?.title?.toLowerCase(),
      intervalType: "ALL_TIME",
    };

    if (filters?.status && filters?.status[0].length > 0) {
      body["subscriptionStatus"] = filters?.status[0];
    } else {
      body["subscriptionStatus"] = [
        DTO.SubscriptionStatus.ACTIVE,
        DTO.SubscriptionStatus.FREE_TRIAL,
        DTO.SubscriptionStatus.PENDING_PAYMENT,
      ];
    }

    if (filters?.branchIds && filters?.branchIds.length > 0) {
      body["branchIds"] = filters?.branchIds;
    }

    if (filters?.regionIds && filters?.regionIds.length > 0) {
      body["regionIds"] = filters?.regionIds;
    }

    if (filters?.stateIds && filters?.stateIds.length > 0) {
      body["stateIds"] = filters?.stateIds;
    }

    if (filters?.subscriptionTypes && filters?.subscriptionTypes.length > 0) {
      body["subscriptionTypes"] = filters?.subscriptionTypes;
    }

    const { data } = await axios.post<DTO.Page<DTO.SubscribedPlayerSearchResponse>>(
      `${SUBSCRIPTIONS_NO_STATS_SERVICE}/${coachId}?page=${page}&size=${size}`,
      body,
      {
        withCredentials: true,
      }
    );

    return data;
  }

  static async searchCanceledPlayersByTag(
    coachId: string,
    sort: string, // UNUSED
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.SubscribedPlayerSearchResponse>> {
    const body: { [key: string]: any } = {
      searchTerm: filters?.title,
    };

    if (filters?.status && filters?.status[0].length > 0) {
      body["tags"] = filters?.status[0];
    }
    body["subscriptionStatus"] = [DTO.SubscriptionStatus.CANCELED, DTO.SubscriptionStatus.INACTIVE];

    if (filters?.branchIds && filters?.branchIds.length > 0) {
      body["branchIds"] = filters?.branchIds;
    }

    if (filters?.regionIds && filters?.regionIds.length > 0) {
      body["regionIds"] = filters?.regionIds;
    }

    if (filters?.stateIds && filters?.stateIds.length > 0) {
      body["stateIds"] = filters?.stateIds;
    }

    if (filters?.subscriptionTypes && filters?.subscriptionTypes.length > 0) {
      body["subscriptionTypes"] = filters?.subscriptionTypes;
    }

    const { data } = await axios.post<DTO.Page<DTO.SubscribedPlayerSearchResponse>>(
      `${SUBSCRIPTIONS_NO_STATS_SERVICE}/${coachId}?page=${page}&size=${size}`,
      body,
      {
        withCredentials: true,
      }
    );

    return data;
  }

  static async searchProspects(coachId: string): Promise<DTO.Page<DTO.ProspectResponse>> {
    const { data } = await axios.get<DTO.ProspectResponse[]>(`${PROSPECTS_SERVICE}/coaches/${coachId}`, {
      withCredentials: true,
    });

    const searchResponse: DTO.Page<DTO.ProspectResponse> = {
      content: data,
    };
    return searchResponse;
  }

  static async addPlayerUpgradeLead(playerId: number) {
    try {
      const response = await axios.post(`${PROSPECTS_SERVICE}/upgrades/player/${playerId}`, {
        withCredentials: true,
      });
      if (response.status !== 200) {
        console.warn("Error adding player upgrade lead", response);
      }
    } catch (e) {
      console.error("Error adding player upgrade lead", e);
    }
  }

  static async searchAdminProspects(
    searchTerm: string,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.ProspectResponse>> {
    const body = {
      searchTerm: searchTerm,
    };

    const response = await axios.post<DTO.Page<DTO.ProspectResponse>>(
      `${PROSPECTS_SERVICE}/search?page=${page}&size=${size}&sort=createdDate,DESC`,
      body,
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  static async statistics(
    playerId: number,
    intervalType: string = "MONTHLY"
  ): Promise<DTO.BasePlayerStatisticsResponse> {
    const response = await axios.post<DTO.BasePlayerStatisticsResponse>(
      `${Constants.API_URL}/players/statistics/${playerId}`,
      { intervalType },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  /**
   * @deprecated - The function should not be used. Use searchProspects
   */
  static async searchRegisteredPlayers(coachId: string): Promise<DTO.Page<DTO.PlayerAccount>> {
    const { data } = await axios.get<DTO.PlayerAccount[]>(`${SUBSCRIPTIONS_SERVICE}/${coachId}/prospects`, {
      withCredentials: true,
    });

    const searchResponse: DTO.Page<DTO.PlayerAccount> = {
      content: data,
    };
    return searchResponse;
  }

  @catchServiceException()
  static async subscribeEnterprisePlayers(
    coachId: string,
    pricePlanId: number,
    players: DTO.EnterprisePlayerRequest[]
  ): Promise<DTO.EnterprisePlayerResponse> {
    const response = await axios.post<DTO.EnterprisePlayerResponse>(
      `${SUBSCRIPTIONS_SERVICE}/${coachId}/bulk/${pricePlanId}`,
      {
        playersToSubscribe: players,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  /**
   * @deprecated The function should not be used
   */
  @catchServiceException()
  static async setDefaultPrompt(playerId: number, coachId: string): Promise<void> {
    try {
      await axios.put<any>(`${COACH_PLAYERS}/${playerId}/prompts/${coachId}`, {
        withCredentials: true,
      });
      return Promise.resolve();
    } catch (e) {
      if (String(e).includes("409")) {
        return Promise.resolve();
      }

      return Promise.reject(e);
    }
  }

  @catchServiceException()
  static async getCoachData(playerId: number): Promise<DTO.LandingResponse> {
    const response = await axios.get<DTO.LandingResponse>(`${COACH_PLAYERS}/${playerId}/coach/public`, {
      withCredentials: true,
    });
    return response.data;
  }

  @catchServiceException()
  static async updatePlayerQuestions(playerId: number, payload: any): Promise<void> {
    try {
      const response = await axios.put<any>(
        `${ACCOUNT_PLAYERS}/${playerId}`,
        {
          ...payload,
          leadsNeedToCloseTransaction: 0,
          averageIncomesPerTransaction: 0,
          transactionsClosedLastYear: 0,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (e) {
      return Promise.reject("Error updating your questions");
    }
  }

  @catchServiceException()
  static async get(playerId: number): Promise<DTO.AccountResumen> {
    try {
      const response = await axios.get<DTO.AccountResumen>(`${ACCOUNT_PLAYERS}/${playerId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (e) {
      return Promise.reject("Error updating your questions");
    }
  }

  @catchServiceException()
  static async sendFollowUpEmail(coachId: string): Promise<void> {
    await axios.post<void>(`${Constants.API_URL}/accounts/players/send-follow-up-email/${coachId}`, {
      withCredentials: false,
    });
  }

  @catchServiceException()
  static async getPlayerStickers(playerId: number): Promise<DTO.StickerResponse> {
    const response = await axios.get<DTO.StickerResponse>(`${PLAYERS}/owned-stickers/${playerId}`, {
      withCredentials: true,
    });
    return response.data;
  }

  @catchServiceException()
  static async getAllPlayerStickers(playerId: number, coachId: string): Promise<DTO.ComposedStickerResponse> {
    const response = await axios.get<DTO.ComposedStickerResponse>(
      `${PLAYERS}/all-stickers/${playerId}/coach/${coachId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async updatePlayerStickers(
    playerId: string | number,
    stickerRequest: DTO.PlayerStickerRequest
  ): Promise<DTO.StickerResponse> {
    const { data } = await axios.put<DTO.StickerResponse>(
      `${PLAYERS}/owned-stickers/${playerId}`,
      {
        ...stickerRequest,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async updatePlayerProfile(
    playerId: string | number,
    playerProfileUpdateRequest: DTO.PlayerProfileUpdateRequest
  ): Promise<void> {
    const result = await axios.put<void>(
      `${ACCOUNT_PLAYERS}/profile/${playerId}`,
      {
        ...playerProfileUpdateRequest,
      },
      {
        withCredentials: true,
      }
    );
    return result.data;
  }

  static async dashboardInformation(
    playerId: number | undefined,
    intervalType: string,
    coachId?: number | undefined
  ): Promise<DTO.DashboardInformation> {
    const body = {
      intervalType,
      coachId,
      statisticTypes: ["FOLLOWERS", "CLICKS", "GOALS_POSTED", "LIKES", "COMMENTS", "SHARES"],
    };

    const { data } = await axios.post<DTO.DashboardInformation>(`${PLAYERS}/statistics/` + playerId, body, {
      withCredentials: true,
    });

    return camelizeKeys(data) as DTO.DashboardInformation;
  }

  static async quickActions(playerId: number | undefined): Promise<DTO.PlayerQuickAction[]> {
    const { data } = await axios.get<DTO.PlayerQuickAction[]>(`${PLAYERS}/${playerId}/quick-actions`, {
      withCredentials: true,
    });

    return data;
  }
}
