import * as React from "react";
import { useEffect, useState } from "react";
import { Page } from "../../../interfaces";
import { Button, Grid, Input, Pagination, Table } from "semantic-ui-react";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import { exportDataToCsv, useDebounce } from "../../../utils";

export interface UserTableHeader {
  title: string;
  width?: SemanticWIDTHS;
}

export interface UserTableSearchQuery {
  searchTerm: string;
  page: number;
  size: number;
}

interface UserTableProps<T> {
  headers: UserTableHeader[];
  queryFunction: (query: UserTableSearchQuery) => Promise<Page<T>>;
  exportMapper?: (item: T) => {};
  renderRow: (item: T) => React.ReactNode;
  hideSearch?: boolean;
}

const UserTable: React.FC<UserTableProps<any>> = <T,>({
  headers,
  queryFunction,
  exportMapper,
  renderRow,
  hideSearch,
}: UserTableProps<T>) => {
  const [data, setData] = useState<T[]>([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);

  const getMoreData = () => {
    setIsLoading(true);
    queryFunction({ page: pageNumber, size: 20, searchTerm })
      .then(newData => {
        setData(newData.content);
        setTotalPages(newData.totalPages || 0);
        setIsLoading(false);
      })
      .catch(console.error);
  };

  const onNewSearchTerm = useDebounce(() => {
    getMoreData();
  }, 300);

  useEffect(() => {
    onNewSearchTerm();
  }, [searchTerm]);

  useEffect(() => {
    getMoreData();
  }, [pageNumber]);

  const exportData = async () => {
    setExportLoading(true);
    let page = 0;
    let last = false;
    let allData: T[] = [];
    while (!last) {
      const response = await queryFunction({ page, searchTerm, size: 100 });
      last = response.last || false;
      allData = allData.concat(response.content);
      page++;
    }

    const exportData = allData.map(i => exportMapper!(i));

    exportDataToCsv("SocialCoachData", exportData);
    setExportLoading(false);
  };

  return (
    <Grid>
      {(!hideSearch || !!exportMapper) && (
        <Grid.Row>
          <Grid.Column width={6}>
            {!hideSearch && (
              <Input
                loading={isLoading}
                value={searchTerm}
                fluid
                icon="search"
                placeholder="Search by Name or Email..."
                onChange={(_, d) => setSearchTerm(d.value)}
              />
            )}
          </Grid.Column>
          <Grid.Column width={2}>
            {!!exportMapper && (
              <Button
                fluid
                className={"secondary rounded buttonLeft"}
                disabled={exportLoading}
                loading={exportLoading}
                onClick={exportData}
              >
                Export
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column width={16}>
          <Table striped celled compact="very" size="small">
            <Table.Header>
              <Table.Row>
                {headers.map(header => (
                  <Table.HeaderCell key={`user-table-header-${header.title}`} width={header.width}>
                    {header.title}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!isLoading &&
                data.map((item, index) => <Table.Row key={`user-table-row-${index}`}>{renderRow(item)}</Table.Row>)}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid centered>
          <Pagination
            defaultActivePage={1}
            boundaryRange={3}
            pointing
            secondary
            totalPages={totalPages}
            onPageChange={(_, data) => {
              setPageNumber(Number(data.activePage) - 1);
            }}
          />
        </Grid>
      </Grid.Row>
    </Grid>
  );
};

export default UserTable;
