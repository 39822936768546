import * as React from "react";

import { Editor } from "../../../../interfaces";
import { UsersService } from "../../../../services";
import { Table } from "semantic-ui-react";
import UserTable from "../../UserTable";

const EditorsListFC: React.FC = () => {
  return (
    <UserTable
      hideSearch
      queryFunction={query => UsersService.getEditors([], query.page, query.size)}
      headers={[
        { title: "Name", width: 1 },
        { title: "Email", width: 1 },
      ]}
      renderRow={(editor: Editor) => (
        <>
          <Table.Cell>
            {editor.firstName} {editor.lastName}
          </Table.Cell>
          <Table.Cell>{editor.email}</Table.Cell>
        </>
      )}
    />
  );
};

export const EditorsList = EditorsListFC;
