import { SortElement } from "./Search";
import { CoachStatuses } from "./UserStatus";
import { UserGroups } from "./requests/SocialNetworkRequest";

export enum ReportType {
  PROMPT_ENGAGEMENT_REPORT = "PROMPT_ENGAGEMENT_REPORT",
  PLAYERS_REPORT = "PLAYERS_REPORT",
  REMITTANCE_REPORT = "REMITTANCE_REPORT",
  FEATURE_ENGAGEMENT_REPORT = "FEATURE_ENGAGEMENT_REPORT",
}

export interface SearchAccountsQuery {
  profileTypes?: UserGroups[];
  searchTerm?: string;
  coachStatuses?: CoachStatuses[];
  hideDemoAccounts?: boolean;
}

export interface SearchSubcoachesQuery {
  searchTerm?: string;
}

export interface SearchCoachesQuery {
  searchTerm?: string;
  coachStatuses?: CoachStatuses[];
  hideDemoAccounts?: boolean;
}

export interface SearchStatisticsQuery {
  coachId?: string[];
  reportsType?: ReportType[];
}

export interface Page<T> {
  content: T[];
  totalPages?: number;
  totalElements?: number;
  last?: boolean;
  sort?: SortElement;
  numberOfElements?: number;
  first?: boolean;
  size?: number;
  number?: number;
  empty?: boolean;
}

export interface FilterType {
  key: number;
  text: string;
  value: string;
}

export interface HashOf<T> {
  [key: string]: T;
}
