import * as React from "react";
import { CampaignDeliveryMode, dateKey, PromptCampaign, snKey, timeKey } from "../../../interfaces";

import { PromptLargeCard } from "../../Cells";

import { DraggablePromptContainer } from "../../DraggablePromptContainer";
import { reorder } from "../../../utils";

interface OwnProps {
  promptsSorted: PromptCampaign[];
  values: any;
  deliveryMode: CampaignDeliveryMode;
  isAutoPost: boolean;
  fromEdit: boolean;
  editable?: boolean;
  /**
   * Id of prompts that were included previously
   */
  initialPromptsIds?: string[];

  handleSetFieldValue: (field: any, value: any) => void;
  handlePromptsSorted: (campaignPrompt: PromptCampaign[]) => void;
}

type Props = OwnProps;

const PromptCardsFC: React.FC<Props> = ({
  promptsSorted,
  isAutoPost,
  values,
  deliveryMode,
  fromEdit,
  editable = true,
  initialPromptsIds = [],
  handleSetFieldValue,
  handlePromptsSorted,
}) => {
  const onDragEnd = (result: any): void => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const realDestinationIndex = destination.index;
    const realSourceIndex = source.index;
    const newCampaigns = reorder(promptsSorted, realSourceIndex, realDestinationIndex);
    const final = newCampaigns.map((p: PromptCampaign, index) => ({ ...p, sortOrder: index } as PromptCampaign));

    if (handlePromptsSorted) {
      handlePromptsSorted(final);
    }
  };

  const noOrderedPrompts = promptsSorted ? (
    promptsSorted.map((cprompt: PromptCampaign, index: number) => {
      const props = {
        prompt: cprompt.prompt!,
        campaignDeliveryMode: deliveryMode,
        isAutoPost,
        editable: editable || !initialPromptsIds.includes(`${cprompt.prompt!.promptId}`),
        deliveryDateUtc: values[`${cprompt.prompt!.promptId}${dateKey}`],
        deliveryTimeUtc: values[`${cprompt.prompt!.promptId}${timeKey}`],
        socialNetworks: values[`${cprompt.prompt!.promptId}${snKey}`],
        handleSetFieldValue,
      };
      return <PromptLargeCard key={"prompt_card_" + index} {...props} />;
    })
  ) : (
    <div />
  );

  return deliveryMode === "ORDERED_RANDOM" ? (
    <DraggablePromptContainer
      isAutoPost={isAutoPost}
      prompts={promptsSorted}
      campaignDeliveryMode={deliveryMode}
      initialPromptsIds={initialPromptsIds}
      onDragEnd={onDragEnd}
      fromEdit={fromEdit}
      editable={editable}
      socialNetworks={values}
      handleSetFieldValue={handleSetFieldValue}
    />
  ) : (
    <>{noOrderedPrompts}</>
  );
};

export const PromptCards = PromptCardsFC;
