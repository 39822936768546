import * as React from "react";
import { Table } from "semantic-ui-react";
import { PlayerSearchResponse, UserGroups } from "../../../../interfaces";

import "./styles.scss";

import { CoachService, SocialCoachSessionService, SubscriptionServices } from "../../../../services";
import {
  convertToAdminPlayerExport,
  getFullName,
  getLargeSubscriptionType,
  printBasicDate,
  printBasicShortDate,
  URLS,
} from "../../../../utils";
import UserTable from "../../UserTable";
import { ActionList, SocialNetworksSection } from "../../../../components";
import { useState } from "react";
import { AppContext } from "../../../../providers";
import { ReportServices } from "../../../../services/ReportServices";
import { DeletePlayerModal } from "./DeletePlayerModal";
import { UpdateEmailModal } from "../UpdateEmailModal";

interface OwnProps {}

type Props = OwnProps;

const PlayersListFC: React.FC<Props> = () => {
  const { userContext } = React.useContext(AppContext);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerSearchResponse>();
  const [playerModal, setPlayerModal] = useState(false);
  const [deletePlayerModal, setDeletePlayerModal] = useState(false);

  const impersonateCoach = (player: PlayerSearchResponse) => {
    CoachService.getById(player.coachProfile!!.coachId.toString()).then(response => {
      SocialCoachSessionService.impersonate(response.user.email!!).then(() => {
        // Redirect to coach dashboard
        window.location.assign(URLS.coach.dashboard);
      });
    });
  };

  const impersonatePlayer = (player: PlayerSearchResponse) => {
    SocialCoachSessionService.impersonate(player.email!!).then(() => {
      // Redirect to player dashboard
      window.location.assign(URLS.player.dashboard);
    });
  };

  const sendReportHandler = (player: PlayerSearchResponse) => {
    ReportServices.sendPlayerReport(player.playerId!!)
      .then(() => {
        alert("Success! Your report was sent to support accounts");
      })
      .catch(() => {
        alert("Oops. Something went wrong. Please refresh this page and try again.");
      });
  };

  const handleAction = (index: number, player: PlayerSearchResponse): void => {
    setSelectedPlayer(player);
    switch (index) {
      case 0:
        setPlayerModal(true);
        break;
      case 1:
        sendReportHandler(player);
        break;
      case 2:
        impersonateCoach(player);
        break;
      case 3:
        impersonatePlayer(player);
        break;
      case 4:
        setDeletePlayerModal(true);
        break;
    }
  };

  const getActions = (player: PlayerSearchResponse) => {
    const list = [
      { content: "Edit email", icon: "pencil" },
      { content: "Send Report", icon: "file alternate outline" },
      { content: "Impersonate Coach", icon: "sign-in" },
      { content: "Impersonate Player", icon: "sign-in" },
    ];

    if (
      player.subscriptionSummary?.status === "CANCELED" &&
      userContext?.account.scGroups?.includes(UserGroups.SYSTEM_ADMIN)
    ) {
      list.push({ content: "Delete Player", icon: "trash" });
    }
    return list;
  };

  return (
    <>
      <UserTable
        headers={[{ title: "Player" }, { title: "Subscription" }, { title: "Coach" }, { title: "Actions" }]}
        queryFunction={query => SubscriptionServices.searchAllSubscriptions(query.searchTerm, query.page, query.size)}
        exportMapper={(player: PlayerSearchResponse) => convertToAdminPlayerExport(player)}
        renderRow={(player: PlayerSearchResponse) => (
          <>
            <Table.Cell>
              <div className={"playerAdminCellTitle"}>
                <code>{player.playerId}</code> - {getFullName(player.firstName, player.lastName) || "No Name"}
              </div>
              <div>{player.email}</div>
              <div>{player.phone}</div>
              <div>
                <SocialNetworksSection
                  actives={player.socialNetworks?.map(sn => sn.networkId)}
                  allSocialNetworks={["FB", "LI", "IG", "IG_STORY", "TW", "TT"]}
                />
              </div>
            </Table.Cell>

            <Table.Cell>
              <div>{getLargeSubscriptionType(player.subscriptionSummary?.tier)}</div>
              <div>{player.subscriptionSummary?.status}</div>
              {player.lastLoginDate && <div>{"Last Login: " + printBasicShortDate(player.lastLoginDate)}</div>}
            </Table.Cell>

            <Table.Cell>
              {player.coachProfile && (
                <>
                  <div>
                    <span className={"playerAdminCellTitle"}>
                      {getFullName(player.coachProfile.firstName, player.coachProfile.lastName) || "No Name"}
                    </span>
                  </div>
                  <div>{player.coachProfile.companyName}</div>
                  <div>{player.coachProfile.email}</div>
                </>
              )}
            </Table.Cell>

            <Table.Cell>
              <ActionList items={getActions(player)} handleAction={async index => handleAction(index, player)} />
            </Table.Cell>
          </>
        )}
      />
      <UpdateEmailModal
        open={playerModal}
        onClose={() => setPlayerModal(false)}
        currentEmail={selectedPlayer?.email}
        userId={selectedPlayer?.userId}
      />
      <DeletePlayerModal open={deletePlayerModal} onClose={() => setDeletePlayerModal(false)} player={selectedPlayer} />
    </>
  );
};

export const PlayersList = PlayersListFC;
