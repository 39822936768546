import UserTable from "../../UserTable";
import { SocialCoachSessionService, UsersService } from "../../../../services";
import { Table } from "semantic-ui-react";
import * as React from "react";
import { printBasicShortDate, URLS } from "../../../../utils";
import { ActionList } from "../../../../components";
import { UpdateEmailModal } from "../UpdateEmailModal";
import { useState } from "react";
import * as DTO from "../../../../interfaces/dtos";

export const AssistantsList: React.FC = () => {
  const [emailModal, setEmailModal] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState<DTO.CompleteAccount>();

  const getActions = (_: DTO.CompleteAccount) => {
    return [
      { content: "Edit email", icon: "pencil" },
      { content: "Impersonate Assistant", icon: "sign-in" },
    ];
  };

  const handleAction = (index: number, assistant: DTO.CompleteAccount): void => {
    setSelectedAssistant(assistant);
    switch (index) {
      case 0:
        setEmailModal(true);
        break;
      case 1:
        impersonate(assistant);
        break;
    }
  };

  const impersonate = (assistant: DTO.CompleteAccount) => {
    SocialCoachSessionService.impersonate(assistant.user.email!!).then(() => {
      // Redirect to coach dashboard
      window.location.assign(URLS.coach.dashboard);
    });
  };

  return (
    <>
      <UserTable
        headers={[{ title: "Info" }, { title: "Status" }, { title: "Coach" }, { title: "Actions" }]}
        queryFunction={query => {
          return UsersService.searchSubCoaches(
            {
              searchTerm: query.searchTerm,
            },
            query.page,
            query.size
          );
        }}
        renderRow={(assistant: DTO.CompleteAccount) => (
          <>
            <Table.Cell>
              <div>
                {assistant.profile?.firstName} {assistant.profile?.lastName}
              </div>
              <div>{assistant.user.email}</div>
            </Table.Cell>
            <Table.Cell>
              <div>{assistant.user.status}</div>
              <div>Created: {printBasicShortDate(assistant.user.createdDate)}</div>
              <div>Last Login: {printBasicShortDate(assistant.user.latestLoginDate)}</div>
            </Table.Cell>
            <Table.Cell>
              <div>
                <code>{assistant.coach?.id}</code> - {assistant.coach?.contactEmail}
              </div>
              <div>{assistant.coach?.companyName ? assistant.coach.companyName : <i>No Company Name</i>}</div>
            </Table.Cell>
            <Table.Cell>
              <ActionList items={getActions(assistant)} handleAction={async index => handleAction(index, assistant)} />
            </Table.Cell>
          </>
        )}
      />
      <UpdateEmailModal
        open={emailModal}
        onClose={() => setEmailModal(false)}
        currentEmail={selectedAssistant?.user?.email}
        userId={selectedAssistant?.user?.userId}
      />
    </>
  );
};
