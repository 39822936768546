import * as React from "react";
import { Media } from "../../../../../interfaces";

import "./styles.scss";
import { ActionList } from "../../../../ActionList";
import { useMemo } from "react";
import { Button, Icon, Popup } from "semantic-ui-react";

interface MediaItemInfoProps {
  item: Media;
  isForReel: boolean;
  hasAutosticker: boolean;
  mediaStatus: string | undefined;
  naturalWidth: number | undefined;
  naturalHeight: number | undefined;
  handleRemoveMedia: (id: number) => void;
  handleCrop: (id: number) => void;
  handleAutosticker: (id: number) => void;
  handleAutostickerProgress: (id: number) => void;
}

const MediaItemInfoFC: React.FC<MediaItemInfoProps> = ({
  item,
  isForReel,
  hasAutosticker,
  mediaStatus,
  naturalWidth,
  naturalHeight,
  handleRemoveMedia,
  handleCrop,
  handleAutosticker,
  handleAutostickerProgress,
}: MediaItemInfoProps) => {
  const feedRatios = [
    { value: 1, label: "1:1" },
    { value: 4.0 / 5, label: "4:5" },
    { value: 16.0 / 9, label: "16:9" },
    { value: 1.91, label: "1.91:1" },
  ];

  const reelRatios = [{ value: 9.0 / 16, label: "9:16" }];

  const acceptableRatios = useMemo(() => {
    if (isForReel) return reelRatios;
    else return feedRatios;
  }, [isForReel]);

  const reelSizes = { minWidth: 540, minHeight: 960, maxWidth: 1080, maxHeight: 1920 };
  const feedSizes = { minWidth: 200, minHeight: 200, maxWidth: 2048, maxHeight: 2048 };

  const acceptableSizes = useMemo(() => {
    if (isForReel) return reelSizes;
    else return feedSizes;
  }, [isForReel, reelSizes, feedSizes]);

  const aspectRatio = useMemo(() => {
    if (!naturalHeight || !naturalWidth) return null;
    const ratio = acceptableRatios.find(ratio => {
      return Math.abs(naturalWidth / ratio.value - naturalHeight) <= 1;
    });
    return ratio?.label;
  }, [naturalWidth, naturalHeight, acceptableRatios]);

  const fileSizeError = useMemo(() => {
    if (naturalHeight && naturalWidth) {
      if (naturalHeight < acceptableSizes.minHeight || naturalWidth < acceptableSizes.minWidth) {
        return `The file must be bigger than ${acceptableSizes.minWidth}x${acceptableSizes.minHeight}`;
      } else if (naturalHeight > acceptableSizes.maxHeight || naturalWidth > acceptableSizes.maxWidth) {
        return `The file must be smaller than ${acceptableSizes.maxWidth}x${acceptableSizes.maxHeight}`;
      }
      return undefined;
    } else {
      return null;
    }
  }, [
    naturalWidth,
    naturalHeight,
    acceptableSizes.minHeight,
    acceptableSizes.minWidth,
    acceptableSizes.maxHeight,
    acceptableSizes.maxWidth,
  ]);

  const ACTION_DOWNLOAD = "Download";
  const ACTION_CROP = "Crop";
  const ACTION_AUTOSTICKER = "Configure auto-sticker";
  const ACTION_AUTOSTICKER_PROGRESS = "Auto-sticker results";
  const ACTION_DELETE = "Remove file";
  const actions = useMemo(() => {
    const actionList = [
      { content: ACTION_DOWNLOAD, icon: "download" },
      { content: ACTION_CROP, icon: "compress" },
      { content: ACTION_AUTOSTICKER, icon: "images outline" },
      {
        content: ACTION_AUTOSTICKER_PROGRESS,
        icon: "images",
        onlyIf: () => {
          return hasAutosticker;
        },
      },
      { content: ACTION_DELETE, icon: "trash" },
    ];

    return actionList.filter(a => !a.onlyIf || a.onlyIf());
  }, [hasAutosticker]);

  const handleAction = async (index: number) => {
    const action = actions[index];
    switch (action.content) {
      case ACTION_DOWNLOAD:
        downloadFile();
        break;
      case ACTION_CROP:
        if (item.category !== "IMAGE") {
          alert("At this time, only images can be cropped using this tool.");
        } else {
          handleCrop(item.id);
        }
        break;
      case ACTION_AUTOSTICKER:
        handleAutosticker(item.id);
        break;
      case ACTION_AUTOSTICKER_PROGRESS:
        handleAutostickerProgress(item.id);
        break;
      case ACTION_DELETE:
        handleRemoveMedia(item.id);
        break;
    }
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = item.uri;
    link.target = "_blank";
    link.download = item.fileName || `SocialCoachMedia.${item.extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={"media-info-container"}>
      <div className={"menu"}>
        <ActionList items={actions} handleAction={handleAction} iconName={"ellipsis vertical"} />
      </div>
      {mediaStatus && (
        <div>
          <label>
            <b>Status: </b>
            {mediaStatus}
          </label>
        </div>
      )}
      <div>
        <label>
          {!!fileSizeError && (
            <Popup
              className="customPopup"
              content={fileSizeError}
              trigger={<Icon name="exclamation circle" className={"errorIcon"} link />}
            />
          )}
          <b>Dimensions: </b>
          {naturalWidth} x {naturalHeight}
        </label>
      </div>
      <div>
        <label>
          {aspectRatio === undefined && (
            <Popup
              className="customPopup"
              content={
                `Please modify this content to match one of the following aspect ratios: ` +
                `${acceptableRatios.map(r => r.label).join(", ")}`
              }
              trigger={<Icon name="exclamation circle" className={"errorIcon"} link />}
            />
          )}
          <b>Aspect Ratio: </b>
          {aspectRatio === null ? "" : aspectRatio || "Invalid"}
        </label>
      </div>
      <div>
        <label>
          <b>File Type: </b> {item.extension}
        </label>
      </div>
      {hasAutosticker && (
        <div>
          <label>
            <b>Autosticker: </b>Enabled
          </label>
        </div>
      )}
      <div>
        {aspectRatio === undefined && item.category === "IMAGE" && (
          <Button
            onClick={e => {
              handleCrop(item.id);
              e.preventDefault();
            }}
            size={"mini"}
            basic
            className={"fixButton"}
          >
            Fix Aspect Ratio
          </Button>
        )}
      </div>
    </div>
  );
};

export const MediaItemInfo = MediaItemInfoFC;
