import * as React from "react";
import { Grid, Form, Message, Modal } from "semantic-ui-react";
import { Formik } from "formik";
import { UsersService } from "../../../../services";

interface OwnProps {
  userId?: number;
  currentEmail?: string;
  open: boolean;
  onClose: () => void;
}

export const UpdateEmailModal: React.FC<OwnProps> = ({ userId, currentEmail, open, onClose }) => {
  return (
    <Modal open={open} size={"mini"} onClose={onClose} closeIcon={true}>
      <Modal.Header>Update User Email - ${currentEmail}</Modal.Header>
      <Modal.Content>
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column>
              <Formik
                initialValues={{
                  email: currentEmail || "",
                }}
                onSubmit={async ({ email }, { setErrors }) => {
                  try {
                    const updatedUser = {
                      email,
                      username: email,
                    };
                    UsersService.updateUserCredentials(userId!!, updatedUser)
                      .then(() => {
                        onClose();
                      })
                      .catch(() => {
                        setErrors({
                          email: "Invalid email address",
                        });
                      });
                  } catch (e) {
                    setErrors({
                      email: "Invalid email address",
                    });
                  }
                }}
              >
                {({ values, errors, handleChange, handleSubmit }) => {
                  const errorList = Object.values(errors) as string[];
                  return (
                    <Form
                      data-elm-id="update-email-information-form"
                      error={errorList.length > 0}
                      onSubmit={handleSubmit}
                    >
                      <Form.Group widths="equal">
                        <Form.Input
                          value={values.email}
                          onChange={handleChange}
                          data-elm-id="email-input"
                          type="text"
                          size="mini"
                          id="email"
                          name="email"
                          fluid
                          label="Email"
                          required
                          placeholder="Email"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Button compact type="submit" floated={"right"}>
                          Save
                        </Form.Button>
                      </Form.Group>

                      <Message error list={errorList} />
                    </Form>
                  );
                }}
              </Formik>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};
