import * as React from "react";
import { SideBarBase } from "../SideBarBase";

import { MenuElement } from "../../interfaces";
import { URLS } from "../../utils/routes";

interface Props {
  history: any;
  readyToContinue?: boolean;
}

const SideBarFC: React.FC<Props> = ({ history, readyToContinue = true }) => {
  const menu: MenuElement[] = [
    {
      title: "Users",
      path: URLS.admin.dashboard,
    },
    {
      title: "Video Catalyst",
      path: URLS.editor.editQueueList,
    },
    {
      title: "Video Scripts",
      path: URLS.videoScripts,
    },
  ];

  const canGoToMenu = (menuItem: MenuElement, currentStep?: string): boolean => {
    return true;
  };

  return <SideBarBase history={history} menu={menu} canGoToMenu={canGoToMenu} readyToContinue={readyToContinue} />;
};

export const AdminSideBar = SideBarFC;
